<template>
    <div v-if="$store.getters.logged && isGiurato">
        <div class="position-absolute ms-3 mt-0">
            <a v-if="!valutaEsame && !isGiurato" href="/#/home" class="bi bi-arrow-left-circle-fill fs-2"></a>
            <a v-else-if="isGiurato && valutaEsame" @click.prevent="showElencoPrenotazioni()" class="bi bi-arrow-left-circle-fill fs-2"></a>
            <a v-else-if="isGiurato && showAnagrafica" @click.prevent="showVoti()" class="bi bi-arrow-left-circle-fill fs-2"></a>
            <!-- <a v-else-if="isGiurato" href="/#/loginesterni" class="bi bi-arrow-left-circle-fill fs-2"></a> -->
        </div>

        <!-- ALERT ALLERGIE -->
        <div class="alert alert-dark position-fixed w-75 text-center translate-middle-x start-50" :class="showAllergia ? '' : 'd-none'">
            <div class="row">
                <div class="col"><h4>Allergie di <b>{{ infoAllergie.studente }}</b></h4></div>
                <div class="col-1 pe-5"><button type="button" class="btn-close btn-lg opacity-100" @click="showAllergia = false"></button></div>
            </div>
            <ul>
                <li class="text-start" v-for="allergia in infoAllergie.elencoAllergie" v-bind:key="allergia">
                    {{ allergia }}.
                </li>
            </ul>
        </div>

        <div v-if="isGiurato" class="row mt-3 mb-3">
            <div class="col-auto mx-auto border rounded-3 bgAlma">
                <p class="fw-bold mb-0 text-center text-white">
                    <i class="bi bi-person-fill text-white"></i> {{ $store.state.nomeGiurato }}
                    <i class="bi bi bi-grip-vertical text-white"></i> TAV. {{ $store.state.tavoloGiurato }}
                </p>
            </div>
        </div>

        <div v-if="!valutaEsame && !showAnagrafica" class="container text-center col-lg-6" :class="showAllergia ? 'pe-none' : ''">

            <div class="row"><h1 class="fw-bold">Elenco Esami</h1></div>

            <div class="row mt-3">
                <p class="fw-bold mb-0 text-center">Valutazione Esami di oggi {{ oggiToShow }}</p>
            </div>

            <div class="my-2">
                <div class="accordion-item">
                    <h2 class='accordion-header'>
                        <button type="button" id="accordionButton"
                        class="accordion-button rounded-1 bg-warning bg-opacity-50"
                        :class="showInfoIndicatori ? '':'collapsed'" @click="showInfoIndicatori = !showInfoIndicatori">
                            Indicazioni per la giuria d’esame
                        </button>
                    </h2>
                    <div id="accordionHeader" class="accordion-collapse collapse" :class="showInfoIndicatori ? 'show':''">
                        <div class="accordion-body" >
                            <p class="text-start mb-0" v-html="indicatori"></p>
                        </div>
                    </div>
                </div>
            </div>

            <table class="table table-sm mt-2">
                <thead>
                    <tr>
                        <th class="col"><h3 class="fw-bold mb-0 text-start">Studente</h3></th>
                        <th class="col"><h3 class="fw-bold mb-0 text-start">Edizione Corso</h3></th>
                        <th class="col-4"><h3 class="fw-bold mb-0">Esami</h3></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="prenotazione in prenotazioni" v-bind:key="prenotazione.Id">
                        <th class="text-start align-middle ps-3">{{ prenotazione.Account__r.LastName }} {{ prenotazione.Account__r.FirstName }}</th>
                        <td class="text-start align-middle">{{ prenotazione.Opportunita__r.Edizione_Corso__r.Name }}</td>
                        <td>
                            <button :class="!prenotazione.valutata ? 'btn-sm mx-auto w-75': 'btn-sm mx-auto btn-success active w-75'" @click="getEsame(prenotazione)">
                                {{ !prenotazione.valutata ? 'Da valutare' : 'Valutato' }}
                            </button>
                        </td>
                        <td class="px-0"><span v-if="prenotazione.Opportunita__r.Allergie__c" @click="showAllergie(prenotazione.Opportunita__r.Allergie__c, prenotazione.Opportunita__r.Account.Name)" class="bi bi-file-earmark-medical text-danger fs-3 align-text-top"></span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <vediEsame v-if="valutaEsame" :name="name" :piatti="piatti" :esame="esameSelezionato" :colspan="esameSelezionato.Edizione_Corso__r.Corso__r.N_indicatori_valutazione_piatti__c"
        v-on:setStudenteAnagrafica="setStudenteAnagrafica($event)"></vediEsame>
        <anagraficaStudente v-if="showAnagrafica" :studenteIds="studenteIds" :nome="nomeStudente"></anagraficaStudente>
    </div>
</template>

<script>
import vediEsame from '../components/vediEsame.vue'
import anagraficaStudente from '../components/anagraficaStudente.vue'

export default {
    name: 'Tesi',
    components:{
        vediEsame,
        anagraficaStudente
    },
    data() {
        return {
            name: '',
            valutaEsame: false,
            piatti: [],
            esameSelezionato: {},
            mVotiEsami: new Map(),
            prenotazioni: [],
            infoPrenotazioni: {},
            infoAllergie: {},
            showAnagrafica: false,
            studenteIds: {},
            nomeStudente: '',
            showAllergia: false,

            oggi: new Date(),
            oggiToShow: '',

            showInfoIndicatori: false,
            indicatori: `<ul>
                            <li>Ogni candidato presenterà <b>un piatto</b> un <b>due versioni</b>: la prima sarà una riproduzione fedele della ricetta tradizionale, mentre la seconda sarà una versione personale e creativa dello stesso piatto.</li>
                            <li>Il piatto viene scelto dal candidato in base alle esperienze fatte durante il periodo di formazione in Italia. <b>La scelta non è vincolata al luogo di stage.</b></li>
                            <li>La ricetta tradizionale sarà frutto di ricerca sul campo (interviste, assaggi, incontri con chef e produttori) e teorica (fonti scritte e online). </li>
                            <li>La ricetta personale sarà una rielaborazione creativa della prima nella quale saranno applicate le conoscenze tecniche acquisite.</li>
                            <li>L’esame si svolge in un’<b>unica sessione</b> in cui tutta la classe presenta prima il piatto tradizionale e di seguito il piatto personale.</li>
                            <li>Ad ogni piatto, il candidato proporrà <b>una bevanda in abbinamento</b> (NB: la bevanda può essere la stessa per entrambe i piatti).</li>
                            <li>I candidati metteranno a disposizione dei giurati un booklet che guidi il giurato attraverso il proprio lavoro.</li>
                            <li><b>Il piatto personale verrà presentato alla giuria direttamente dal candidato.</b></li>
                            <li><b>Al termine dell’esame</b> la giuria avrà la facoltà di chiamare individualmente i candidati per <b>eventuali domande o considerazioni</b> sul lavoro svolto.</li>
                            <li>Ogni giurato avrà a disposizione una <b>scheda di valutazione</b> da compilare entro fine sessione.</li>
                        </ul>`
        };
    },
    computed: {
        isGiurato: function() {
            if(this.$store.state.nomeGiurato != null && this.$store.state.nomeGiurato != '') return true
            return false
        }
    },
    created(){
        // if(this.$store.state.userprofile.Name.includes('Giurato')) this.$store.commit('GET_GIURATO')
        // else this.$store.commit('RESET_GIURATO_DATA')
        this.$store.commit('GET_GIURATO')

        this.oggiToShow = this.oggi.toISOString().split('T')[0].split('-').reverse().join('-')
        this.oggi = this.oggi.toISOString().split('T')[0]
        this.getPrenotazioniEsame()
    },
    methods:{
        showElencoPrenotazioni() {
            console.log('--> showElencoPrenotazioni')
            this.name = ''
            this.valutaEsame = false
            this.showAnagrafica = false
            this.getQtaVotiEsami(this.prenotazioni)
        },
        getPrenotazioniEsame() {
            console.log('--> getPrenotazioniEsame')
            console.log('this.oggi', this.oggi)

            var data = {
                giornoEsame: this.oggi, 
                numTavolo: this.$store.state.tavoloGiurato
            }
            this.$store.dispatch('getPrenotazioniEsame', data)
            .then(resp => {
                this.getQtaVotiEsami(resp)
            })
        },
        getQtaVotiEsami(prenot) {
            var data = {
                prenotazioni: prenot,
                idDocente: this.$store.state.userprofile.Id, 
                nomeGiurato: this.$store.state.nomeGiurato
            }
            this.$store.dispatch('getQtaVoti', data)
            .then(resp => {
                if(resp != null && resp.qtaVotiEsami != null) {
                    for(var qve of resp.qtaVotiEsami) {
                        if(qve.votiDati == resp.numVotiTot) this.mVotiEsami.set(qve.Prenotazione__c, true)
                        else this.mVotiEsami.set(qve.Prenotazione__c, false)
                    }
                }
                for(var p of prenot) {
                    if(p.valutata == null) p['valutata'] = false
                    if(this.mVotiEsami.get(p.Id)) p.valutata = true
                }
                this.prenotazioni = prenot
            })
        },
        showAllergie(allergie, studente) {
            console.log('--> showAllergie')
            console.log(allergie, studente)

            this.showAllergia = true

            this.infoAllergie = {
                studente: studente,
                elencoAllergie: allergie.split(';')
            }
        },
        setStudenteAnagrafica(p) {
            console.log('setStudenteAnagrafica')
            this.showAnagrafica = true
            this.valutaEsame = false
            this.studenteIds = {
                opptyId: p.Opportunita__c,
                accountId: p.Account__c
            }
            this.nomeStudente = p.Account__r.Name
        },
        showVoti() {
            console.log('--> showElencoPrenotazioni')
            this.showAnagrafica = false
            this.valutaEsame = true
        },
        getEsame(prenotazione) {
            console.log('--> getEsame')
            console.log('prenotazione selezionata',prenotazione)

            this.$store.dispatch('getEsameStudente', {giornoEsame: this.oggi, idAccount:this.$store.state.userprofile.Id, prenotazione: prenotazione})
            .then(resp => {

                var esame = resp[0]
                if(esame.Tesi__c) {
                    this.name = esame.Opportunita__r.Account.Name
                    var idTesi = esame.Tesi__c
                    console.log(idTesi)

                    console.log('esame',esame)
                    var indicatoriPiatti = esame.Edizione_Corso__r.Corso__r.N_indicatori_valutazione_piatti__c
                    var indicatoriEsame = 0
                    if(esame.Edizione_Corso__r.Corso__r.N_indicatori_valutazione_esami__c) indicatoriEsame = esame.Edizione_Corso__r.Corso__r.N_indicatori_valutazione_esami__c
                    
                    esame['indicatoriEsame'] = {}
                    esame['commentiIndicatoriEsame'] = {}
                    for(var i = 1; i <= indicatoriEsame; i++) {
                        var indicatore = esame.Edizione_Corso__r.Corso__r['Indicatore_valutazione_'+i+'__c']
                        esame.indicatoriEsame[indicatore] = 0
                        esame.commentiIndicatoriEsame[indicatore] = ''
                    }
                    this.esameSelezionato = esame
                    
                    this.$store.dispatch('getPiattiEValutazione', {idTesi: idTesi})
                    .then(resp => {
                        console.log('piatti: ',resp);

                        this.esameSelezionato['mediaIndicatoriEsame'] = 0
                        this.esameSelezionato['mediaTotale'] = 0

                        for(var piatto of resp) {
                            var voti = {}
                            var commenti = {}
                            for(var i = indicatoriEsame+1; i <= indicatoriEsame+indicatoriPiatti; i++) {
                                var indicatore = esame.Edizione_Corso__r.Corso__r['Indicatore_valutazione_'+i+'__c']
                                voti[indicatore] = ''
                                commenti[indicatore] = ''
                            }
                            piatto['voti'] = voti
                            piatto['commenti'] = commenti
                            piatto['listaVoti'] = []
                            piatto['media'] = 0
                        }
                        this.piatti = resp
                        
                        this.valutaEsame = true
                    })
                } else alert('Questo studente non ha una tesi valida per questo esame.')
            })
        }
    }
  }
</script>
