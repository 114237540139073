<template>


  <div class="home">
    <LogIn/>
  </div>
 
</template>
 
 
<script>
import LogIn from '../components/LogIn.vue';
export default {
  name: 'Home',
  components: {
    LogIn
  },
  data: function () {
    return {};
  },
  created () {
    if(this.$store.getters.logged && this.$store.state.userprofile.Name && !this.$store.state.userprofile.Name.includes('Giurato')) {
      var a = document.createElement("a");
      a.setAttribute('href', '/#/home');
      a.click();
    } else if (this.$store.state.userprofile.Name && this.$store.state.userprofile.Name.includes('Giurato')) {
      this.$store.commit('RESET_USER_DATA');
    }
  },
  methods: {}
}
</script> 